import Vue from 'vue';
import nl from 'vuetify/es5/locale/nl';
import en from 'vuetify/es5/locale/en';
import Vuetify from 'vuetify/lib';
import { dark, light } from './theme';
import icons from './icons';
import store from '@/application/store';

Vue.use(Vuetify);
export default new Vuetify({
  icons,
  lang: {
    locales: { nl, en },
    current: store.getters['authorisation/profile']?.language || 'nl',
  },
  theme: {
    dark: false,
    themes: {
      light,
      dark,
    },
  },
  breakpoint: {
    mobileBreakpoint: 1538,
  },
});
