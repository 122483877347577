import Vue from 'vue';
import VueI18n from 'vue-i18n';
import store from '@/application/store';

const locales = {
  nl: require.context('@/locales/nl', true, /[A-Za-z0-9-_,\s]+\.json$/i),
  en: require.context('@/locales/en', true, /[A-Za-z0-9-_,\s]+\.json$/i),
};

Vue.use(VueI18n);

function loadLocaleMessages() {
  const messages = {};
  Object.keys(locales).forEach((locale) => {
    messages[locale] = {};
    const components = locales[locale];

    components.keys().forEach(component => {
      const matched = component.match(/([A-Za-z0-9-_]+)\./i);
      const componentName = matched[1];
      messages[locale][componentName] = components(component);
    });
  });
  return messages;
}

const i18n = new VueI18n(
  {
    locale: 'nl' || navigator.language.split('-')[0],
    fallbackLocale: 'nl',
    messages: loadLocaleMessages(),
    numberFormats: {
      'nl': {
        currency: {
          style: 'currency',
          currency: 'EUR',
        },
        percent: {
          style: 'percent',
          maximumFractionDigits: 2,
        },
        factor: {
          maximumSignificantDigits: 4,
        },
        twoDecimals: {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        },
      },
    },
  },
  {
    locale: 'en' || navigator.language.split('-')[0],
    fallbackLocale: 'nl',
    messages: loadLocaleMessages(),
    numberFormats: {
      'en': {
        currency: {
          style: 'currency',
          currency: 'EUR',
        },
        percent: {
          style: 'percent',
          maximumFractionDigits: 2,
        },
        factor: {
          maximumSignificantDigits: 4,
        },
        twoDecimals: {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        },
      },
    },
  },
);

i18n.locale = store.getters['authorisation/profile']?.language || 'nl';

export default i18n;
