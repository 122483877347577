import { index } from '@/modules/sectors/api/sector.js';

export default {
  namespaced: true,
  state: {
    data: [],
    isFetching: false,
  },
  mutations: {
    set(state, sectors) {
      state.data = sectors;
    },
    setIsFetching(state, isFetching) {
      state.isFetching = isFetching;
    },
  },
  actions: {
    async fetch(context) {
      if (context.state.data.length > 1 || context.state.isFetching) {
        return;
      }
      context.commit('setIsFetching', true);
      const response = await index();
      context.commit('set', response.data.data);
      context.commit('setIsFetching', false);
    },
  },
  getters: {
    all: (state) => {
      return state.data;
    },
  },
};
