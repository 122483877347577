const light = {
  primary: '#5A392D',
  secondary: '#4D5A58',
};

const dark = {
  primary: '#2A73C5',
};

export { light, dark };
