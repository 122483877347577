import { RouteConfig } from 'vue-router';

const authorisationRoutes: RouteConfig = {
  path: '',
  component: () => import('@/templates/Authorisation.vue'),
  children: [
    {
      path: 'login',
      name: 'login',
      component: () => import('@/modules/authorisation/views/Login.vue'),
      props: true,
    },
    {
      path: 'auth/callback',
      name: 'auth.callback',
      component: () => import('@/modules/authorisation/views/AuthorisationCallback.vue'),
    },
    {
      path: 'password/forgotten',
      name: 'password.forgotten',
      component: () => import('@/modules/authorisation/views/PasswordForgotten.vue'),
    },
    {
      path: 'password/reset/:token',
      name: 'password.reset',
      component: () => import('@/modules/authorisation/views/PasswordReset.vue'),
    },
    {
      path: 'invitation/accept/:token',
      name: 'invitation.accept',
      component: () => import('@/modules/authorisation/views/InvitationAccept.vue'),
    },
  ],
};

export default authorisationRoutes;
