import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faAngleUp,
  faArrowsAltV,
  faBars,
  faBell,
  faBirthdayCake,
  faBriefcase,
  faBuilding,
  faBusinessTime,
  faCalendarAlt,
  faCar,
  faChartBar,
  faCheck,
  faCheckCircle,
  faTimesCircle,
  faChevronDoubleDown,
  faChevronDoubleUp,
  faChevronDoubleRight,
  faChevronDoubleLeft,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faChevronUp,
  faCircle as farCircle,
  faClock,
  faCog,
  faCopy,
  faDotCircle,
  faEnvelope,
  faExclamation,
  faExclamationTriangle,
  faEye,
  faEyeSlash,
  faFax,
  faFile,
  faFileContract,
  faFileExcel,
  faFileInvoice,
  faFilePdf,
  faFilePowerpoint,
  faFileSignature,
  faFileVideo,
  faFileWord,
  faFilter,
  faFolder,
  faFolderOpen,
  faGenderless,
  faGlobeEurope,
  faHandshake,
  faHome,
  faHourglassHalf,
  faInfoCircle,
  faList,
  faMapMarkerAlt,
  faMars,
  faMinus,
  faMoneyCheckEditAlt,
  faPaperclip,
  faPaperPlane,
  faPencilAlt,
  faPhone,
  faPlus,
  faQuestionCircle,
  faSearch,
  faSquare,
  faStepBackward,
  faStepForward,
  faStickyNote,
  faSync,
  faTimes,
  faTrain,
  faTrashAlt,
  faUpload,
  faUser,
  faUsers,
  faUserUnlock,
  faVenus,
} from '@fortawesome/pro-light-svg-icons';

import {
  faCaretDown,
  faCheckSquare,
  faCircle,
  faEllipsisV,
  faHexagon,
  faLockAlt,
  faMinusSquare,
  faStar,
  faStarHalf,
  faTags,
  faTable,
  faTriangle,
  faUnlockAlt,
  faUser as faUserSolid,
} from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

library.add(
  faCopy,
  faTrashAlt,
  faTimes,
  faTags,
  faTable,
  faCheckSquare,
  faMinusSquare,
  faStar,
  faStarHalf,
  faCheck,
  faCheckCircle,
  faTimesCircle,
  faInfoCircle,
  faExclamation,
  faExclamationTriangle,
  faChevronLeft,
  faChevronRight,
  faSquare,
  faAngleUp,
  faChevronDown,
  faBars,
  faCaretDown,
  faDotCircle,
  faFileInvoice,
  faCircle,
  farCircle,
  faPencilAlt,
  faStar,
  faSync,
  faStepBackward,
  faStepForward,
  faArrowsAltV,
  faPaperclip,
  faPaperPlane,
  faPlus,
  faMinus,
  faUser,
  faUserSolid,
  faCog,
  faHome,
  faHandshake,
  faBusinessTime,
  faUsers,
  faBriefcase,
  faChartBar,
  faUpload,
  faFile,
  faFileVideo,
  faFileWord,
  faFileExcel,
  faFilePowerpoint,
  faFilePdf,
  faTriangle,
  faHexagon,
  faBell,
  faSearch,
  faCar,
  faTrain,
  faPhone,
  faEnvelope,
  faFax,
  faGlobeEurope,
  faMapMarkerAlt,
  faCalendarAlt,
  faClock,
  faList,
  faBirthdayCake,
  faHourglassHalf,
  faQuestionCircle,
  faEllipsisV,
  faEye,
  faEyeSlash,
  faFolder,
  faFolderOpen,
  faGenderless,
  faMars,
  faVenus,
  faFilter,
  faUserUnlock,
  faBuilding,
  faLockAlt,
  faUnlockAlt,
  faChevronDown,
  faChevronDoubleDown,
  faChevronUp,
  faChevronDoubleUp,
  faChevronDoubleRight,
  faChevronDoubleLeft,
  faMoneyCheckEditAlt,
  faStickyNote,
  faFileContract,
  faFileSignature,
);

const icons = {
  /*vuetify default icons*/
  complete: ['fal', 'check'],
  cancel: ['fal', 'times'],
  close: ['fal', 'times'],
  delete: ['fal', 'trash-alt'],
  clear: ['fal', 'times'],
  success: ['fal', 'check-circle'],
  failure: ['fal', 'times-circle'],
  info: ['fal', 'info-circle'],
  warning: ['fal', 'exclamation'],
  error: ['fal', 'exclamation-triangle'],
  prev: ['fal', 'chevron-left'],
  next: ['fal', 'chevron-right'],
  doubleRight: ['fal', 'chevron-double-right'],
  doubleLeft: ['fal', 'chevron-double-left'],
  checkboxOn: ['fas', 'check-square'],
  checkboxOff: ['fal', 'square'],
  checkboxIndeterminate: ['fas', 'minus-square'],
  delimiter: ['fal', 'circle'],
  sort: ['fal', 'angle-up'],
  expand: ['fal', 'chevron-down'],
  menu: ['fal', 'bars'],
  subgroup: ['fas', 'caret-down'],
  dropdown: ['fas', 'caret-down'],
  radioOn: ['fal', 'dot-circle'],
  radioOff: ['fal', 'circle'],
  edit: ['fal', 'pencil-alt'],
  ratingEmpty: ['fal', 'star'],
  ratingFull: ['fas', 'star'],
  ratingHalf: ['fas', 'star-half'],
  loading: ['fal', 'sync'],
  first: ['fal', 'step-backward'],
  last: ['fal', 'step-forward'],
  unfold: ['fal', 'arrows-alt-v'],
  file: ['fal', 'paperclip'],
  plus: ['fal', 'plus'],
  minus: ['fal', 'minus'],
  copy: ['fal', 'copy'],

  /*custom icons*/

  profile: ['fal', 'user'],
  profileSolid: ['fas', 'user'],
  settings: ['fal', 'cog'],
  home: ['fal', 'home'],
  notifications: ['fal', 'bell'],
  search: ['fal', 'search'],
  birthday: ['fal', 'birthday-cake'],
  waiting: ['fal', 'hourglass-half'],
  unknown: ['fal', 'question-circle'],
  fabDots: ['fas', 'ellipsis-v'],
  visible: ['fal', 'eye'],
  invisible: ['fal', 'eye-slash'],
  filter: ['fal', 'filter'],

  invoice: ['fal', 'file-invoice'],
  vacancy: ['fal', 'handshake'],
  expense: ['fal', 'money-check-edit-alt'],
  shift: ['fal', 'business-time'],
  candidate: ['fal', 'users'],
  employer: ['fal', 'briefcase'],
  report: ['fal', 'chart-bar'],
  role: ['fal', 'user-unlock'],
  location: ['fal', 'building'],
  department: ['fal', 'building'],
  position: ['fal', 'briefcase'],
  placement: ['fal', 'file-contract'],

  ['send-email']: ['fal', 'paper-plane'],
  upload: ['fal', 'upload'],
  fileUnknown: ['fal', 'file'],
  fileVideo: ['fal', 'file-video'],
  fileWord: ['fal', 'file-word'],
  fileExcel: ['fal', 'file-excel'],
  filePowerpoint: ['fal', 'file-powerpoint'],
  filePdf: ['fal', 'file-pdf'],
  folderOpen: ['fal', 'folder-open'],
  folderClosed: ['fal', 'folder'],

  tags: ['fas', 'tags'],
  table: ['fas', 'table'],

  circle: ['fas', 'circle'],
  triangle: ['fas', 'triangle'],
  hexagon: ['fas', 'hexagon'],

  shiftPlaced: ['fal', 'handshake'],

  car: ['fal', 'car'],
  train: ['fal', 'train'],

  phone: ['fal', 'phone'],
  email: ['fal', 'envelope'],
  fax: ['fal', 'fax'],
  website: ['fal', 'globe-europe'],
  address: ['fal', 'map-marker-alt'],

  date: ['fal', 'calendar-alt'],
  time: ['fal', 'clock'],

  viewList: ['fal', 'list'],
  viewCalendar: ['fal', 'calendar-alt'],
  viewChart: ['fal', 'chart-bar'],

  genderUnknown: ['fal', 'genderless'],
  genderMale: ['fal', 'mars'],
  genderFemale: ['fal', 'venus'],

  lock: ['fas', 'lock-alt'],
  unlock: ['fas', 'unlock-alt'],

  prioCritical: ['fal', 'chevron-double-up'],
  prioHigh: ['fal', 'chevron-up'],
  prioNormal: ['fal', 'minus'],
  prioLow: ['fal', 'chevron-down'],
  prioMinor: ['fal', 'chevron-double-down'],

  internalNote: ['fal', 'sticky-note'],
  signed: ['fal', 'file-signature'],
};

export default {
  values: Object.fromEntries(Object.entries(icons).map(([key, icon]) => [key, {
    component: FontAwesomeIcon,
    props: { icon },
  }])),
};
