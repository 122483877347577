import { get } from '@/application/api/implementations/app';
import router from '@/router/index.ts';

export default {
  namespaced: true,
  state: {
    statusUrl: `${process.env.VUE_APP_PUBLIC_API}/status`,
    plannedMaintenance: null,
    redirectPath: null,
    isUnderMaintenance: false,
  },
  mutations: {
    setIsUnderMaintenance(state, isUnderMaintenance) {
      state.isUnderMaintenance = isUnderMaintenance;
    },
    setPlannedMaintenance(state, plannedMaintenance) {
      state.plannedMaintenance = plannedMaintenance;
    },
    setCheckStatusInterval(state, checkStatusInterval) {
      state.checkStatusInterval = checkStatusInterval;
    },
    setRedirectPath(state, redirectPath) {
      state.redirectPath = redirectPath;
    },
  },
  actions: {
    async fetchStatus({ state, commit, getters, dispatch }) {
      try {
        const res = await get(state.statusUrl);
        
        commit('setPlannedMaintenance', res.data.data.plannedMaintenance);

        if (res.status === 200 && !getters.isUnderMaintenance) return;

        dispatch('turnMaintenanceModeOff');
      } catch {
        return;
      }
    },
    turnMaintenanceModeOn({ commit }) {
      commit('setIsUnderMaintenance', true);
      commit('setRedirectPath', window.location.href);
      router.replace({ name: '503' });
    },
    turnMaintenanceModeOff({ commit, getters }) {
      commit('setIsUnderMaintenance', false);
      commit('setPlannedMaintenance', null);
      window.location = getters.redirectPath;
    },
  },
  getters: {
    isUnderMaintenance: (state) => {
      return state.isUnderMaintenance;
    },
    plannedMaintenance: (state) => {
      return state.plannedMaintenance;
    },
    checkStatusInterval: (state) => {
      return state.checkStatusInterval;
    },
    redirectPath: (state) => {
      return state.redirectPath ?? '/';
    },
  },
};
