<template>
  <v-btn
    v-bind="$attrs"
    :color="color"
    :depressed="depressed"
    :tile="tile"
    v-on="$listeners"
  >
    <slot />
  </v-btn>
</template>

<script>
export default {
  name: 'KBtn',
  props: {
    color: {
      type: String,
      default: 'primary',
    },
    depressed: {
      type: Boolean,
      default: true,
    },
    tile: {
      type: Boolean,
      default: true,
    },
  },
};
</script>
