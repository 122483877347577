import { destroy, get, post, put } from '@/application/api/implementations/app';
import { getPaginated } from '@/application/api/implementations/app/wrapper.js';

function index(page, perPage, search, sortBy, descending, params) {
  return getPaginated('vacancy', page, perPage, search, sortBy, descending, params);
}

function employmentTypes() {
  return get('employment');
}

/**
 * @param vacancyId {number}
 */
function show(vacancyId) {
  return get(`vacancy/${vacancyId}`);
}

/**
 * @param formData {Vacancy}
 */
function create(formData) {
  return post('vacancy', formData);
}

/**
 * @param formData {Vacancy}
 */
function update(formData) {
  return put(`vacancy/${formData.id}`, formData);
}

/**
 * @param vacancyId {number}
 */
function remove(vacancyId) {
  return destroy(`vacancy/${vacancyId}`);
}

export {
  index,
  employmentTypes,
  show,
  create,
  update,
  remove,
};
