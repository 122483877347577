import store from '@/application/store/index';
import { NavigationGuard } from 'vue-router/types/router';
import settingsMenu from '@/router/menus/settings';

const SettingsGuard: NavigationGuard = (to, from, next) => {
  if (to.name !== 'settings') {
    next();
    return;
  }
  
  const can = store.getters['authorisation/can'];
  const authorisedMenuItems = settingsMenu.filter(
    (item) => can(item?.permission) ?? true,
  );
  
  if (authorisedMenuItems.length) {
    next(authorisedMenuItems[0]?.route);
    return;
  }
  
  next({ name: '403' });
};

export default SettingsGuard;
