export default {
  namespaced: true,
  state: {
    breadcrumbs: [],
  },
  mutations: {
    breadcrumbs(state, breadcrumbs) {
      state.breadcrumbs = breadcrumbs;
    },
  },
  actions: {
    breadcrumbs({ commit }, breadcrumbs) {
      commit('breadcrumbs', breadcrumbs);
    },
  },
  getters: {
    breadcrumbs: (state) => {
      return state.breadcrumbs;
    },
  },
};
