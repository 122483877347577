import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import store from '@/application/store/index';
import authorisation from './routes/authorisation';
import authorised from './routes/authorised';
import global from './routes/global';

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: '',
    redirect: { name: 'dashboard' },
  },
  authorisation,
  authorised,
  global,
  {
    path: '/signing-complete',
    name: 'signing.complete',
    component: () => import('@/modules/placement/views/SigningComplete.vue'),
  },
  {
    path: '/503',
    name: '503',
    component: () => import('@/modules/errorHandling/views/UnderMaintenance.vue'),
    beforeEnter: (to, from, next) => {
      if (!store.getters['status/isUnderMaintenance']) {
        next({ name: 'dashboard' });
      }
      next();
    },
  },
  {
    path: '*',
    redirect: '404',
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: routes,
});

router.beforeEach((to, from, next) => {
  if (store.getters['status/isUnderMaintenance'] && to.name !== '503') {
    next({ name: '503' });
  }
  if (to.matched.some(route => route.name === 'settings') && !store.getters['authorisation/can']('organisationSettings.show')) {
    next({
      name: 'dashboard',
    });
  }
  next();
});

export default router;
