class Model {
  public mapForRequest() {
    throw new Error(`${this.constructor.name}::mapForRequest unset`);
  }

  public mapResponse(data: Record<string, any>): Record<string, any> {
    return this.initializeObjectFromApi(data, this);
  }

  private initializeObjectFromApi(data: Record<string, unknown>, assignable: Record<string, any>): Record<string, any> {
    Object.keys(assignable)
      .forEach(key => {
        const value = assignable[key];
        const type = typeof value;

        if (typeof data === 'undefined') {
          assignable[key] = value;
          return;
        }

        const apiValue = data[key];
        assignable[key] = this.convertByType(type, value, apiValue);
      });

    return assignable;
  }

  private convertByType(type: string, currentValue: any, apiValue: any): any {
    if (type === 'object' && currentValue && typeof currentValue.mapResponse === 'function') {
      return typeof apiValue === 'object' && apiValue !== null ? currentValue.mapResponse(apiValue) : currentValue;
    } else if (type === 'string') {
      return apiValue ? String(apiValue) : '';
    } else if (type === 'boolean') {
      return Boolean(apiValue);
    } else if (type === 'number') {
      return parseFloat(apiValue);
    }
    return apiValue || currentValue;
  }
}

export default Model;
