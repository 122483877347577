import Vue from 'vue';
import App from './App.vue';
// @ts-ignore
import { i18n, vuetify } from './plugins';
import router from './router/index';
import './scss/main.scss';
import store from '@/application/store/index';

Vue.prototype.$store = store;

Vue.config.productionTip = false;

const app = new Vue({
  router,
  // @ts-ignore
  vuetify,
  store,
  i18n,
  render: (h) => h(App),
}).$mount('#app');

// @ts-ignore
if (window.Cypress) {
  // @ts-ignore
  window.app = app;
}
