import { employmentTypes } from '@/modules/vacancy/api/vacancy.js';

export default {
  namespaced: true,
  state: {
    data: [],
  },
  mutations: {
    set(state, employments) {
      state.data = employments;
    },
  },
  actions: {
    async fetchEmploymentTypes(context) {
      if (context.state.data.length > 1 || this.isFetching) {
        return;
      }
      this.isFetching = true;
      const response = await employmentTypes();
      const { data: { data } } = response;
      context.commit('set', data);
      this.isFetching = false;
    },
  },
  getters: {
    all: ({ data }) => data,
  },
};
