import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from 'vuex-persistedstate';

// @ts-ignore
import authorisation from '@/modules/authorisation/store/authorisation.js';
// @ts-ignore
import error from '@/modules/errorHandling/store/error.js';
// @ts-ignore
import sectors from '@/modules/sectors/store/sectors.js';
// @ts-ignore
import employment from '@/modules/vacancy/store/employment.js';
// @ts-ignore
import settings from './modules/settings.js';
// @ts-ignore
import filters from './modules/filters.js';
// @ts-ignore
import breadcrumbs from './modules/breadcrumbs.js';
// @ts-ignore
import status from './modules/status.js';

Vue.use(Vuex);

export default new Vuex.Store({
  plugins: [
    createPersistedState({
      paths: [
        'authorisation',
        'settings',
        'filters',
      ],
    }),
  ],
  modules: {
    authorisation,
    error,
    sectors,
    employment,
    settings,
    filters,
    breadcrumbs,
    status,
  },
});
