import AuthorisationGuard from '@/router/guards/AuthorisationGuard';
import SettingsGuard from '@/router/guards/SettingsGuard';
import { RouteConfig } from 'vue-router';

const authorisedRoutes: RouteConfig = {
  path: '',
  beforeEnter: AuthorisationGuard,
  component: () => import('@/templates/Default.vue'),
  children: [
    {
      path: '',
      name: 'dashboard',
      component: () => import('@/modules/dashboard/views/Dashboard.vue'),
    },
    {
      path: 'werkgevers',
      name: 'employer.index',
      component: () => import('@/modules/employer/views/Table.vue'),
    },
    {
      path: 'werkgever/:employerId/',
      component: () => import('@/modules/employer/views/Layout.vue'),
      children: [
        {
          path: '',
          name: 'employer.show',
          component: () => import('@/modules/employer/views/Show.vue'),
        },
        {
          path: 'documenten',
          name: 'employer.documents',
          component: () => import('@/modules/employer/views/FileManager.vue'),
        },
        {
          path: 'contactpersonen',
          name: 'employer.persons',
          component: () => import('@/modules/person/views/Table.vue'),
        },
        {
          path: 'contactpersoon/:personId',
          name: 'employer.person',
          component: () => import('@/modules/person/views/Dossier.vue'),
        },
        {
          path: 'locaties',
          name: 'employer.location.index',
          component: () => import('@/modules/location/views/Table.vue'),
        },
        {
          path: 'locatie/:locationId/',
          component: () => import('@/modules/location/views/Layout.vue'),
          children: [
            {
              path: '',
              name: 'employer.location.show',
              component: () => import('@/modules/location/views/Show.vue'),
            },
            {
              path: 'afdelingen',
              name: 'employer.location.department.index',
              component: () => import('@/modules/department/views/Table.vue'),
            },
            {
              path: 'afdeling/:departmentId/',
              component: () => import('@/modules/department/views/Layout.vue'),
              children: [
                {
                  path: '',
                  name: 'employer.location.department.show',
                  component: () => import('@/modules/department/views/Show.vue'),
                },
              ],
            },
          ],
        },
        {
          path: 'uitsluitingen',
          name: 'employer.exclusion',
          component: () => import('@/modules/employer/views/Exclusion.vue'),
        },
        {
          path: 'prijs-afspraken',
          name: 'employer.priceAgreement',
          component: () => import('@/modules/priceAgreements/views/AgreementsTable.vue'),
        },
        {
          path: 'prijs-afspraak/:priceAgreementId',
          name: 'employer.priceAgreement.show',
          component: () => import('@/modules/priceAgreements/views/Show.vue'),
        },
        {
          path: 'prijs-afspraak/:priceAgreementId/periodes',
          name: 'employer.priceAgreement.periods',
          component: () => import('@/modules/priceAgreements/views/PeriodsTable.vue'),
        },
        {
          path: 'prijs-afspraak/:priceAgreementId/periode/:priceAgreementDetailId',
          name: 'employer.priceAgreement.period',
          component: () => import('@/modules/priceAgreements/views/Show.vue'),
        },
        {
          path: 'instellingen',
          name: 'employer.settings',
          component: () => import('@/modules/employer/views/Settings.vue'),
        },
        {
          path: 'plaatsingen',
          name: 'employer.placement',
          component: () => import('@/modules/employer/views/Placements.vue'),
        },
        {
          path: 'facturen',
          name: 'employer.invoices',
          component: () => import('@/modules/employer/views/Invoices.vue'),
        },
        {
          path: 'facturen/:invoiceId?',
          name: 'employer.invoice.pdf',
          component: () => import('@/modules/employer/views/InvoicePdfPreview.vue'),
        },
      ],
    },
    {
      path: 'kandidaten',
      name: 'candidate.index',
      component: () => import('@/modules/candidate/views/Index.vue'),
    },
    {
      path: 'kandidaat/:candidateId/',
      component: () => import('@/modules/candidate/views/Layout.vue'),
      children: [
        {
          path: '',
          name: 'candidate.show',
          component: () => import('@/modules/candidate/views/Dossier.vue'),
        },
        {
          path: 'documenten',
          name: 'candidate.documents',
          component: () => import('@/modules/candidate/views/FileManager.vue'),
        },
        {
          path: 'persoons-gegevens',
          name: 'candidate.personalDetails',
          component: () => import('@/modules/candidate/views/PersonalDetails.vue'),
        },
        {
          path: 'administratief',
          name: 'candidate.admin',
          component: () => import('@/modules/candidate/views/Administrative.vue'),
        },
        {
          path: 'achtergrond',
          name: 'candidate.background',
          component: () => import('@/modules/candidate/views/Background.vue'),
        },
        {
          path: 'wensen',
          name: 'candidate.wishes',
          component: () => import('@/modules/candidate/views/Wishes.vue'),
        },
        {
          path: 'contracten',
          name: 'candidate.contract',
          component: () => import('@/modules/contract/views/Table.vue'),
        },
        {
          path: 'plaatsingen',
          name: 'candidate.placement',
          component: () => import('@/modules/candidate/views/Placements.vue'),
        },
        {
          path: 'zzp',
          name: 'candidate.selfEmployment',
          component: () => import('@/modules/candidate/views/SelfEmployment.vue'),
        },
        {
          path: 'facturen',
          name: 'candidate.invoices',
          component: () => import('@/modules/candidate/views/Invoices.vue'),
        },
        {
          path: 'facturen/:invoiceId?',
          name: 'candidate.invoice.pdf',
          component: () => import('@/modules/candidate/views/InvoicePdfPreview.vue'),
        },
        // {
        //   path: 'documenten',
        //   name: 'candidate.location.documents',
        //   component: () => import('@/views/authorised/candidate/FileManager.vue'),
        // },
      ],
    },
    {
      path: 'tijdregistraties/',
      name: 'timeRegistration.index',
      component: () => import('@/modules/timeRegistration/views/Index.vue'),
      children: [
        {
          path: ':timeRegistrationId',
          name: 'timeRegistration.show',
        },
      ],
    },
    {
      path: 'declaraties/',
      name: 'expenseClaim.index',
      component: () => import('@/modules/expenseClaim/views/Index.vue'),
      children: [
        {
          path: ':declarationId',
          name: 'expenseClaim.show',
        },
      ],
    },
    {
      path: 'diensten',
      name: 'shift.index',
      component: () => import('@/modules/shift/views/Table.vue'),
    },
    {
      path: 'dienst/',
      component: () => import('@/modules/shift/views/Layout.vue'),
      children: [
        {
          path: ':shiftId',
          name: 'shift.show',
          component: () => import('@/modules/shift/views/Show.vue'),
        },
      ],
    },
    {
      path: 'instellingen/',
      beforeEnter: SettingsGuard,
      component: () => import('@/modules/settings/views/SettingsViewWrapper.vue'),
      name: 'settings',
      children: [
        {
          path: 'bedrijfs-entiteiten',
          name: 'settings.company-entities',
          component: () => import('@/modules/companyEntity/views/CompanyEntities.vue'),
        },
        {
          path: 'bedrijfs-entiteit/:entityId',
          name: 'settings.company-entity',
          component: () => import('@/modules/companyEntity/views/TradeNames.vue'),
        },
        {
          path: 'werkgever/document-categorieen',
          name: 'settings.employer-document-category',
          component: () => import('@/modules/employer/views/EmployerDocumentCategorySettings.vue'),
        },
        {
          path: 'kandidaat/document-categorieen',
          name: 'settings.candidate-document-category',
          component: () => import('@/modules/candidate/views/CandidateDocumentCategorySettings.vue'),
        },
        {
          path: 'document-categorieen',
          name: 'settings.document-category',
          component: () => import('@/modules/candidate/views/CandidateDocumentCategorySettings.vue'),
        },
        {
          path: 'intermediair-rollen',
          name: 'settings.intermediary-roles',
          component: () => import('@/modules/permissions/views/IntermediaryRole.vue'),
        },
        {
          path: 'werkgever-rollen',
          name: 'settings.employer-roles',
          component: () => import('@/modules/permissions/views/EmployerRole.vue'),
        },
        {
          path: 'functies',
          name: 'settings.positions',
          component: () => import('@/modules/position/views/Positions.vue'),
        },
        {
          path: 'contract-template',
          name: 'settings.contract-template',
          component: () => import('@/modules/contract/views/ContractTemplates.vue'),
        },
        {
          path: 'plaatsing-document-template',
          name: 'settings.placement-document-template',
          component: () => import('@/modules/placement/views/PlacementDocumentTemplates.vue'),
        },
        {
          path: 'branches',
          name: 'settings.branches',
          component: () => import('@/modules/branch/views/Branches.vue'),
        },
        {
          path: 'public-api-access',
          name: 'settings.public-api-access',
          component: () => import('@/modules/publicApiAccess/views/publicApiAccessIndex.vue'),
        },
        {
          path: 'taken',
          name: 'settings.task',
          component: () => import('@/modules/task/views/TaskDefinition.vue'),
        },
        {
          path: 'gebruikers',
          name: 'settings.users',
          component: () => import('@/modules/users/views/Table.vue'),
        },
        {
          path: 'gebruiker/:userId/',
          component: () => import('@/modules/users/views/Layout.vue'),
          children: [
            {
              path: '',
              name: 'settings.users.show',
              component: () => import('@/modules/users/views/Show.vue'),
            },
          ],
        },
      ],
    },
    {
      path: 'taken',
      name: 'task',
      component: () => import('@/modules/task/views/TaskOverview.vue'),
    },
    {
      path: 'vacatures',
      name: 'vacancy.index',
      component: () => import('@/modules/vacancy/views/Table.vue'),
    },
    {
      path: 'vacature/:vacancyId/',
      component: () => import('@/modules/vacancy/views/Layout.vue'),
    },
    {
      path: 'facturen/',
      component: () => import('@/modules/invoice/views/Layout.vue'),
      children: [
        {
          path: 'concepten',
          name: 'invoice.concept.index',
          component: () => import('@/modules/invoice/views/ConceptsTable.vue'),
        },
        {
          path: 'facturen',
          name: 'invoice.index',
          component: () => import('@/modules/invoice/views/InvoicesTable.vue'),
        },
        {
          path: 'facturen/:invoiceId?',
          name: 'invoice.pdf',
          component: () => import('@/modules/invoice/views/InvoicePdfPreview.vue'),
        },
        {
          path: 'extern',
          name: 'invoice.externalInvoices.index',
          component: () => import('@/modules/invoice/views/ExternalInvoicesTable.vue'),
        },
        {
          path: 'extern/:id',
          name: 'invoice.externalInvoices.view',
          component: () => import('@/modules/invoice/views/ExternalInvoice.vue'),
        },
        {
          path: 'concept/:id',
          name: 'invoice.concept',
          component: () => import('@/modules/invoice/views/ConceptView.vue'),
        },
      ],
    },
    {
      path: 'rapportage/:reportName?/:reportType?',
      name: 'report.show',
      component: () => import('@/modules/report/views/Show.vue'),
    },
    {
      path: 'plaatsingen',
      name: 'placement.index',
      component: () => import('@/modules/placement/views/Placements.vue'),
    },
  ],
};

export default authorisedRoutes;
