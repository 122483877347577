<template>
  <v-app>
    <RouterView v-if="loaded" />
    <v-overlay v-else>
      <VProgressCircular
        indeterminate
        size="64"
      />
    </v-overlay>
    <TheConfirmationDialog />
    <TheSnackbarQueueHandler />
    <UnderMaintenanceAlert />
  </v-app>
</template>

<script>
import TheConfirmationDialog from '@/components/TheConfirmationDialog.vue';
import { mapActions, mapGetters } from 'vuex';
import TheSnackbarQueueHandler from '@/components/TheSnackbarQueueHandler.vue';
import UnderMaintenanceAlert from '@/components/UnderMaintenanceAlert.vue';
import eventBus from '@/application/eventBus';

export default {
  name: 'App',
  components: {
    TheSnackbarQueueHandler,
    TheConfirmationDialog,
    UnderMaintenanceAlert,
  },
  data() {
    return {
      loaded: false,
    };
  },
  computed: {
    ...mapGetters({
      isLoggedIn: 'authorisation/isLoggedIn',
      isUnderMaintenance: 'status/isUnderMaintenance',
    }),
  },
  mounted() {
    setInterval(() => {
      if (this.isUnderMaintenance) return;
      this.fetchStatus();
    }, 1000 * 60);

    this.fetchStatus();
    
    if (process.env.VUE_APP_ENV !== 'vue_demo') return;
    const jiraIssueCollector = document.createElement('script');
    jiraIssueCollector.setAttribute('src', 'https://documentready.atlassian.net/s/d41d8cd98f00b204e9800998ecf8427e-T/o2joag/b/24/a44af77267a987a660377e5c46e0fb64/_/download/batch/com.atlassian.jira.collector.plugin.jira-issue-collector-plugin:issuecollector/com.atlassian.jira.collector.plugin.jira-issue-collector-plugin:issuecollector.js?locale=en-US&collectorId=20feca0e');
    document.head.appendChild(jiraIssueCollector);
  },
  async created() {
    /**
     * If the user is already logged in, in this session they never fetched it's profile so we need to do that now
     */
    try {
      await Promise.all([
        ...this.isLoggedIn ? [this.fetchProfile()] : [],
      ]);
    } catch {
      eventBus.$emit('snackbar', {
        color: 'error',
        text: this.$t('errors.generic'),
      });
    }

    this.loaded = true;
  },
  methods: {
    ...mapActions({
      fetchProfile: 'authorisation/fetchProfile',
      fetchStatus: 'status/fetchStatus',
    }),
  },
};
</script>
