export default {
  namespaced: true,
  state: {
    crudFilters: {},
  },
  mutations: {
    setCrudFilters(state, {
      routeName,
      crudFilters,
    }) {
      state.crudFilters[routeName] = crudFilters;
    },
  },
  getters: {
    getCrudFilters: (state) => (routeName) => {
      return state.crudFilters[routeName];
    },
  },
};
