<template>
  <v-dialog
    v-model="showDialog"
    width="500px"
    :persistent="persistent"
  >
    <v-sheet class="pa-5">
      <v-row>
        <v-col class="text-end">
          <v-btn
            color="grey lighten-2"
            fab
            x-small
            dark
            :elevation="0"
            @click="handleCancel"
          >
            <v-icon color="black">
              $close
            </v-icon>
          </v-btn>
        </v-col>
      </v-row>
      <v-row v-if="alert && !alert.disabled">
        <v-col>
          <v-alert
            :dark="alert.dark ?? true"
            :color="alert.color ?? 'orange'"
          >
            {{ alert.text }}
          </v-alert>
        </v-col>
      </v-row>
      <v-row
        class="align-center my-0"
      >
        <v-col style="max-width: 80px">
          <v-icon
            color="primary"
            :size="50"
          >
            $warning
          </v-icon>
        </v-col>
        <v-col>
          <div class="title">
            {{ title }}
          </div>
          <div
            class="body-1"
            v-html="body"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col class="text-end">
          <k-btn
            v-if="showCancel"
            text
            color="primary"
            class="mr-2"
            @click="handleCancel"
          >
            {{ cancelButtonText }}
          </k-btn>
          <k-btn
            color="primary"
            @click="handleContinue"
          >
            {{ confirmButtonText }}
          </k-btn>
        </v-col>
      </v-row>
    </v-sheet>
  </v-dialog>
</template>

<script>
import eventBus from '@/application/eventBus.ts';
import KBtn from '@/components/KButton.vue';

export default {
  name: 'TheConfirmationDialog',
  components: { KBtn },
  data() {
    return {
      persistent: false,
      showDialog: false,
      confirmCallback: null,
      cancelCallback: null,
      title: '',
      body: '',
      confirmButtonText: '',
      cancelButtonText: '',
      showCancel: true,
      alert: null,
    };
  },
  created() {
    eventBus.$on('confirm', (params) => {
      this.title = params.title;
      this.body = params.body;
      this.confirmButtonText = params.confirmButtonText || this.$t('actions.continue');
      this.cancelButtonText = params.cancelButtonText || this.$t('actions.cancel');
      this.showCancel = params.showCancel ?? true;
      this.confirmCallback = params.confirmCallback;
      this.cancelCallback = params.cancelCallback;
      this.persistent = !!params.persistent;
      this.alert = params.alert;
      this.showDialog = true;
    });
  },
  methods: {
    handleContinue() {
      this.showDialog = false;

      if (typeof this.confirmCallback === 'function') {
        this.confirmCallback();
      }
    },
    handleCancel() {
      this.showDialog = false;

      if (typeof this.cancelCallback === 'function') {
        this.cancelCallback();
      }
    },
  },
};
</script>
