export default [
  {
    route: { name: 'settings.company-entities' },
    permission: 'entity.show',
  },
  {
    route: { name: 'settings.employer-document-category' },
    permission: 'setting.document',
  },
  {
    route: { name: 'settings.candidate-document-category' },
    permission: 'setting.document',
  },
  {
    route: { name: 'settings.intermediary-roles' },
    permission: 'role.show',
  },
  {
    route: { name: 'settings.employer-roles' },
    permission: 'role.show',
  },
  {
    route: { name: 'settings.positions' },
    permission: 'position.show',
  },
  {
    route: { name: 'settings.branches' },
    permission: 'branch.show',
  },
  {
    route: { name: 'settings.task' },
    permission: 'setting.taskDefinition',
  },
  {
    route: { name: 'settings.contract-template' },
    permission: 'contractTemplate.show',
  },
  {
    route: { name: 'settings.placement-document-template' },
    permission: 'placementDocumentTemplate.show',
  },
  {
    route: { name: 'settings.public-api-access' },
    permission: 'publicApiAccess.show',
  },
  {
    route: { name: 'settings.users' },
    permission: 'user.show',
  },
];
