import { convertSnakeToCamel } from '@/application/api/util/keyConverter.js';
import { post } from '@/application/api/implementations/app';
import get from '@/modules/authorisation/api/profile.js';

const initialState = {
  token: '',
  permissions: [],
  profile: null,
};

export default {
  namespaced: true,
  state: initialState,
  mutations: {
    setAuthorisationToken(state, token) {
      state.token = token;
    },
    setPermissions(state, permissions) {
      state.permissions = permissions;
    },
    setProfile(state, profile) {
      state.profile = profile;
    },
    resetAuthorisation(state) {
      Object.keys(state).forEach((key) => delete state[key]);
      Object.assign(state, initialState);
    },
  },
  actions: {
    async logout({ commit }) {
      await post('auth/logout');
      commit('resetAuthorisation');
    },
    async fetchProfile({ commit }) {
      const result = await get();
      commit('setPermissions', result.data.data.permissions.map(obj => ({
        ...obj,
        identifier: convertSnakeToCamel(obj.identifier),
      })));

      commit('setProfile', {
        id: result.data.data.id,
        firstName: result.data.data.firstName,
        insertion: result.data.data.insertion,
        lastName: result.data.data.lastName,
        language: result.data.data.language,
        email: result.data.data.email,
      });
    },
  },
  getters: {
    isLoggedIn: (state) => !!state.token.length,
    can: (state) => (permission) =>
      state.permissions.findIndex(item => item.identifier === permission) !== -1,
    profile: (state) => state.profile,
    userId: (state) => state.profile?.id,
  },
};
